/*! Basic Scripts 2020-08-26 */

/*----------------------------------------
  Fixed, Scrolled and Shrinking Header
----------------------------------------*/
$(document).on("scroll", function(){
  if ($(document).scrollTop() > 100){
    $("header").addClass("scrolled");
  } else {
    $("header").removeClass("scrolled");
  }
});


/*----------------------------------------
  Fixed Footer
----------------------------------------*/
$(document).ready(function(){
  function fullmainwindow(){
    var intMinMainHeight = jQuery(window).height() - $('#header').outerHeight() - $('#footer').outerHeight();
    if ($('#main').outerHeight() < intMinMainHeight) {
      $("#main").css('minHeight', intMinMainHeight);
    }
  }
  fullmainwindow();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    fullmainwindow();
  });
});


/*----------------------------------------
  Change Video Size
----------------------------------------*/
$(document).ready(function(){
  function myorientation(){
    var video_large = "files/videos/schwarzer-adler-uttenreuth-1080p.min.mp4";
    var video_medium = "files/videos/schwarzer-adler-uttenreuth-720p.min.mp4";
    var video_small = "files/videos/schwarzer-adler-uttenreuth-480p.min.mp4";
    var video = document.getElementById('hero-video');
    var width_innerHTML = window.innerWidth;
    var height_innerHTML = window.innerHeight;

    if ( video !== null ) {
      if (window.matchMedia("(orientation: landscape)").matches) {
        if ( width_innerHTML < 855 ) {
          video.setAttribute("src", video_small);
        } else if ( width_innerHTML < 1281 ) {
          video.setAttribute("src", video_medium);
        } else {
          video.setAttribute("src", video_large);
        }
      } else {
        if ( height_innerHTML < 721 ) {
          video.setAttribute("src", video_medium);
        } else {
          video.setAttribute("src", video_large);
        }
      }
    }
  }
  myorientation();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    myorientation();
  });
});


/*----------------------------------------
  Top-Link
----------------------------------------*/
$(document).ready(function() {
  $('div.toplink').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.toplink').fadeIn();
    } else {
      $('div.toplink').fadeOut();
    }
  });
  $('div.toplink').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});


/*----------------------------------------
  SIDR-Navigation - Toogler
----------------------------------------*/
$(document).ready(function() {
  $('.navbar-toggle-on').sidr({
    name: 'navbar-toggle',
    source: '.navbar-collapse',
    side: 'left',    /* left, right */
    speed: 500,
    displace: false,
    onOpen: function () {
      document.getElementById("overlay").style.display = "block";
    },
    onClose: function () {
      document.getElementById("overlay").style.display = "none";
    }
  });
  $( window ).resize(function () {
    $.sidr('close', 'navbar-toggle');
  });
  $(".sidr-class-navbar-toggle-off").click(function() {
    $.sidr('close', 'navbar-toggle');
  });
});


/*----------------------------------------
  Sidr-Navigation - Vertical-Menue
----------------------------------------*/

$(document).ready(function() {
  $(".sidr-class-mod_navigation li").each(function(i) {
    if ($(".sidr-class-mod_navigation li").eq(i).hasClass("sidr-class-trail")) {
      $(".sidr-class-mod_navigation li").eq(i).addClass("sidr-class-nav--open");
    }
  });
  $(".sidr-class-mod_navigation a").click(function() {
    var link = $(this);
    var closest_ul = link.closest("ul");
    var parallel_active_links = closest_ul.find(".sidr-class-nav--open");
    var closest_li = link.closest("li");
    var link_status = closest_li.hasClass("sidr-class-nav--open");
    var count = 0;

    closest_ul.find("ul").slideUp(function() {
      if (++count == closest_ul.find("ul").length)
        parallel_active_links.removeClass("sidr-class-nav--open");
    });

    if (!link_status) {
      closest_li.children("ul").slideDown();
      closest_li.addClass("sidr-class-nav--open");
    }
  });
});

// NAVBAR - Dropdown direction
/*
$(document).ready(function() {
  var nlast = 1;
  var count = $('.nav-dropdown ul.level_2').length;
  $('.nav-dropdown ul.level_2').each(function(i){
      if (count <= nlast) {
        $('ul.level_2').eq(i).addClass('dropdown-left');
      }
      count -= 1;
  });
});
*/